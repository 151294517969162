<template>
      <div class="container">
      <div class="container card mt-5 mb-5 bscard">
        <div class="row">
          <div class="col align-self-center">          
                <img src="  assets/banner-image-003.png" class="d-inline-block w-100" alt="Bill Scanners" />
                <h2 class="text-center pt-5">Trimming bills to its lowest</h2>
          </div>
            <div class="col">
              <div class="form-row rootbox">
                <div class="form-group col-md-12">
                  <h4>Quick Connect Info</h4>
                  </div>
                  <div class="form-group col-md-6">
                      <input type="text" placeholder="First Name *" v-model="state.firstname" />
                      <span style="color:Red" v-if="v$.firstname.$error">
                        <i>{{ v$.firstname.$errors[0].$message }}</i>
                      </span>
                  </div>
                  <div class="form-group col-md-6">
                       <input type="text" placeholder="Last Name *" v-model="state.lastname" />
                       <span style="color:Red" v-if="v$.lastname.$error">
                        <i>{{ v$.lastname.$errors[0].$message }}</i>
                       </span>
                    </div>
                  <div class="form-group col-md-12">
                        <input type="text" placeholder="Email *" v-model="state.email" />
                        <span style="color:Red" v-if="v$.email.$error">
                        <i>{{ v$.email.$errors[0].$message }}</i>
                        </span>
                     </div>
                  <div class="form-group col-md-12">
                        <input type="text" placeholder="Mobile *" v-model="state.mobile"/>
                        <span style="color:Red" v-if="v$.mobile.$error">
                        <i>{{ v$.mobile.$errors[0].$message }}</i>
                        </span>
                    </div>
                  <div class="form-group col-md-12">
                           <label for="timeofcall">Prefered time of call:</label>
                          <input type="datetime-local" placeholder="Prefered time of call" v-model="state.calltime"/>
                          <span style="color:Red" v-if="v$.calltime.$error">
                            <i>{{ v$.calltime.$errors[0].$message }}</i>
                          </span>
                     </div>

                  <div class="form-group col-md-12">                  
                  <label>By clicking submit, I give my consent to receive a call back from Bill Scanners account manager</label>
                  </div>
                  <div class="form-group col-md-12">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="customCheck1" checked>
                      <label class="custom-control-label" for="customCheck1">I agree to the <a href="#">Term & Conditions</a> of Bill Scanners</label>
                    </div>
                  </div>
                  
                  <div class="form-group col-md-12">
                    <button class="btn btn-primary nextBtn btn-lg bs-btn w-100"  @click="submitForm" type="button">SUBMIT</button>                    
                    </div>
                      <div>
                        <span style="color:Red" v-if="v$.$error">
                          <i>*Please check for errors above</i>
                        </span>
                      </div>
                </div>    
            </div>
        </div>
      </div>
      </div>
</template>

<script>
import useValidate from '@vuelidate/core'
import {required, email, numeric, minLength, maxLength } from '@vuelidate/validators'
import { reactive, computed } from 'vue'
import {submit_lead_info} from '../store/modules/db/db-lead-info.js'

export default {
  setup(){
    const state = reactive({
      firstname :'',
      lastname:'',
      email : '',
      mobile : '',
      calltime :'',
    })

    const rules = computed( () => {
      return{
        firstname :{ required  },
        lastname : { required },
        email : { required ,  email},
        mobile : { required, numeric, minLength : minLength(10) ,maxLength : maxLength(10) },
        calltime : { required },
      }
    })

    const v$ = useValidate(rules , state)

    return{
      state,
      v$,
    }
  },
   
  methods :{
    async submitForm(){
      this.v$.$validate() // checks all inputs
      if (!this.v$.$error) { // if ANY fail validation
        const resp = await submit_lead_info(this.state.firstname,this.state.lastname,this.state.email,this.state.mobile, this.state.calltime)
        if (resp.status == '201' || resp.state == '200'){
          console.log(resp.data)
          alert('Thank you for filling the form, we will connect with you @ ' + this.state.calltime )
        }
        else{
          alert('Issue while submitting information please resubmit.')
        }
      } else {
        // alert('Form failed validation')
      }
    }
  }
}
</script>

<style scoped>
.root {
  width: 500px;
  margin: 0 auto;
  background-color: #fff;
  padding: 30px;
  margin-top: 20px;
  border-radius: 20px;
}

input {
  border: none;
  outline: none;
  border-bottom: 1px solid #ddd;
  font-size: 1em;
  padding: 5px 0;
  margin: 10px 0 5px 0;
  width: 100%;
}

button {
  background-color: #3498db;
  padding: 10px 20px;
  margin-top: 10px;
  border: none;
  color: white;
}

.demo {
  font-family: sans-serif;
  border: 1px solid #eee;
  border-radius: 2px;
  padding: 20px 30px;
  margin-top: 1em;
  margin-bottom: 40px;
  user-select: none;
  overflow-x: auto;
}
</style>