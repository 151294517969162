// For jQuery

//import Router from 'vue-router'
import { createWebHistory, createRouter } from "vue-router";
import UserInfo from '@/views/UserInfo'
import HomePage from '@/views/HomePage.vue'
import QuickConnect from '@/views/QuickConnect.vue'
import SignIn from '.././components/SignIn.vue'
import Thanks from '.././components/Thanks.vue'

//import NavBar from '@/views/NavBar.vue'

const routes = [
    { 
        path: '/', 
        name: "Home",
        component: HomePage
    },
    { 
        path: '/userinfo', 
        name: "UserInfo",
        component: UserInfo
    },
    {
        path:'/quickconnect',
        name: 'QuickConnect',
        component: QuickConnect
    },
    {
        path:'/SignIn',
        name: 'SignIn',
        component: SignIn
    },
    {
        path:'/Thanks',
        name: 'Thanks',
        component: Thanks
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
  });
  
  export default router;