<template>
  <div class="root">
    <h2>Information Collector</h2>
    <p>
      <input type="text" placeholder="Last Name" v-model="firstname"/>
    </p>
    <p>
      <input type="text" placeholder="First Name" v-model="lastname" />
    </p>
    <p>
      <input type="text" placeholder="Email" v-model="email" />
    </p>
    <p>
      <input type="text" placeholder="Mobile" v-model="mobile"/>
    </p>
    <p>
      <input type="text" placeholder="Prefered time of call" v-model="calltime" />
    </p>
    <p>
      <input type="text" placeholder="Where did you hear about us" v-model="fromwhere" />
    </p>
    <p>
      <input type="text" placeholder="Referral ( Please enter code )" v-model="refcode" />
    </p>
    <p>
      <input type="text" placeholder="Facebook" v-model="fbook" />
    </p>
    <p>
      <input type="text" placeholder="Twitter" v-model="twtr" />
    </p>
    
    <div id="v-model-multiple-checkboxes" class="demo">
        <input type="checkbox" id="internet" value="Internet" v-model="services.interrnet" />
        <label for="interrnet">Internet</label>
        <input type="checkbox" id="cable" value="Cable" v-model="services.cable" />
        <label for="cable">Cable</label>
        <input type="checkbox" id="mobility" value="Mobility" v-model="services.mobility" />
        <label for="mobility">Mobility</label>
        <input type="checkbox" id="homephone" value="Home Phone" v-model="services.homephone" />
        <label for="homephone">Home Phone</label>
        <input type="checkbox" id="homesecurity" value="Home Security" v-model="services.homesecurity" />
        <label for="homesecurity">Home Security</label>
        <input type="checkbox" id="heatingservices" value="Heating / Ac Services" v-model="services.heatingservices" />
        <label for="heatingservices">Heating / Ac Services</label>
        <input type="checkbox" id="maintenanceprotection" value="Maintenance and Protection Plans" v-model="services.maintenanceprotection" />
        <label for="maintenanceprotection">Maintenance and Protection Plans</label>
        <input type="checkbox" id="mortgagecarhome" value="Mortgage - Car / Home" v-model="services.mortgagecarhome" />
        <label for="mortgagecarhome">Mortgage - Car / Home</label>
        <input type="checkbox" id="insurancecarhome" value="Insurance - Car / Home" v-model="services.insurancecarhome" />
        <label for="insurancecarhome">Insurance - Car / Home</label>
        <br />
        <span>Checked names: {{ checkedNames }}</span>
    </div>
    <p>
      <input type="password" placeholder="Password" v-model="password.password"/>
    </p>
    <p>
      <input type="password" placeholder="Confirm Password" v-model="password.confirm" />
    </p>
    <button @click="submitForm">Submit</button>
  </div>
</template>
<script>
export default {
    data() {
        return{
            firstname :'',
            lastname:'',
            email : '',
            mobile : '',
            calltime :'',
            fromwhere : '',
            fbook:'',
            twtr:'',
            password :{
                password : '',
                confirm :''
            },
            services: {
                interrnet : '',
                cable : '',
                mobility : '',
                homephone : '',
                homesecurity : '',
                heatingservices : '',
                maintenanceprotection : '',
                mortgagecarhome : '',
                insurancecarhome : '',
            }
        }  
    },
    methods :{
        submitForm(){
            alert('form submited scuessfully')
        }
    }
}
</script>

<style scoped>
.root {
  width: 500px;
  margin: 0 auto;
  background-color: #fff;
  padding: 30px;
  margin-top: 100px;
  border-radius: 20px;
}

input {
  border: none;
  outline: none;
  border-bottom: 1px solid #ddd;
  font-size: 1em;
  padding: 5px 0;
  margin: 10px 0 5px 0;
  width: 100%;
}

button {
  background-color: #3498db;
  padding: 10px 20px;
  margin-top: 10px;
  border: none;
  color: white;
}

.demo {
  font-family: sans-serif;
  border: 1px solid #eee;
  border-radius: 2px;
  padding: 20px 30px;
  margin-top: 1em;
  margin-bottom: 40px;
  user-select: none;
  overflow-x: auto;
}
</style>