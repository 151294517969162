<template>
      <div class="container-fluid bsheader">
      <div class="container">
      <nav class="navbar navbar-light navbar-expand-md bg-faded justify-content-center">
          <a class="navbar-brand d-flex w-50 mr-auto" href="https://billscanners.com/" >
                <img src="assets/logo.svg" class="d-inline-block" alt="Bill Scanners" style="width:100px;height:77px;" />
              </a>
        <button class="navbar-toggler mob-menu" type="button" data-toggle="collapse" data-target="#collapsingNavbar3">
            <img src="assets/menu-mobile.svg" class="d-inline-block mob-menu-img" alt="Bill Scanners" />
        </button>
        <div class="navbar-collapse collapse w-100" id="collapsingNavbar3">
            <ul class="nav navbar-nav ml-auto W-100 justify-content-end">
                <!-- <li class="nav-item mb-2 active">
                  <a class="nav-link" href="https://billscanners.com/">Home</a>
                </li> -->
                <li class="nav-item mb-2 active">
                  <router-link class="nav-link" :to="{name : 'QuickConnect'}" href="QuickConnect#" >Quick Connect</router-link>
                </li>
                
            </ul>
            <form class="form-inline ml-3">
              <ul class="navbar-nav mr-auto ">
                <li class="nav-item mb-2 bg-primary text-white ">
                  <!-- <router-link class="bs-btn" :to="{name : 'SignIn'}" href="SignIn#" >Register</router-link> -->
                  <a class="bs-btn" href="https://billscanners.com/">Home</a>
                  <!-- <a class="nav-link" :to="{name: 'Home'}">Home Page
                    <span class="sr-only">(current)</span>
                  </a> -->
                </li>
              </ul>
              <!-- <button class="btn btn btn-primary btn-lg my-2 my-sm-0" type="submit">Login -->
                <!-- <a class="nav-link" :to="{name : 'LogIn'}"> LogIn</a> -->
              <!-- </button> -->
            </form>
            
        </div>
    </nav>
      </div> 
    </div> 
</template>

<script>
export default {
    name: 'NavBar'
}
</script>

<style>
</style>