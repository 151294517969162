<template>
    <div>
        <!-- <h2>Home Page</h2> -->
        <QuickConnect />
    </div>
</template>

<script>
import QuickConnect from '../views/QuickConnect.vue'
export default {
    name: 'HomePage',
    components : { QuickConnect}
    
}
</script>

<style>
</style>