// import { reactive } from "@vue/reactivity"
import axios from "axios"
import { String } from "core-js"

// const apiURL ='http://localhost:3000/'
// const createuser =()=>{
//     axios.post(`{apiURL}`,)
// }

export async function getlogin (displayname, email, password) {
    const userinfo = {
        idToken : String,
        email: String,
        emailVerified: Boolean,
        role: [],
        error: Boolean,
        error_msg: String,
    }
    try{
 
        const api_url = "https://us-central1-save-mate-dev.cloudfunctions.net/app/create"
        // const api_url = process.env.API_CREATE_USER
        const body =JSON.stringify({
        displayName : `${displayname}`,
        email : `${email}`,
        password : `${password}`
        })
        // console.log(api_url)
        // console.log(body)
        //  axios({method: 'POST', url: `${urls.apiBaseUrl}/${endPoint}/?tenant_id=3`, headers: header, data: body})

        const rsp = await axios({method: 'POST', url: `${api_url}`,  headers:{"Content-Type":"application/json"}, data: `${body}`})
        if (rsp.status == 200 || rsp.status == 201){
                userinfo.idToken = rsp.data.data.idToken,
                userinfo.email= rsp.data.data.email,
                userinfo.emailVerified= rsp.data.data.emailVerified,
                userinfo.role= rsp.data.data.role,
                userinfo.error = false,
                userinfo.error_msg=''
            }
        // return(userinfo)
    }catch(err){
        userinfo.idToken = ''
        userinfo.email= '',
        userinfo.emailVerified= '',
        userinfo.role= '',
        userinfo.error= true,
        userinfo.error_msg= err.message
    }finally{
        // eslint-disable-next-line no-unsafe-finally
        return(userinfo)
    }
        
    
    // axios({method: 'POST', url: `${api_url}`,  headers:{"Content-Type":"application/json"}, data: `${body}`}).then(response =>{
    //     console.log(response)
    // }).catch(error=>{
    //     console.log(error)
    //   })

  }
