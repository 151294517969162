// import { reactive } from "@vue/reactivity"
import axios from "axios"


export async function submit_lead_info (firstname, lastname, email, mobile, calltime) {
    try{
        // console.log( firstname, lastname, email, mobile, calltime)
        // console.log ("inside login function")
        // const api_url = 'http://localhost:3000/createuser'
        const api_url = "https://us-central1-save-mate-dev.cloudfunctions.net/cd/lead-info/create"
        // const api_url = process.env.API_CREATE_USER
        const body =JSON.stringify({
        first_name : `${firstname}`,
        last_name : `${lastname}`,
        email : `${email}`,
        mobile: `${mobile}`,
        call_time : `${calltime}`
        })
        // console.log(api_url)
        // console.log(body)
        //  axios({method: 'POST', url: `${urls.apiBaseUrl}/${endPoint}/?tenant_id=3`, headers: header, data: body})

        const rsp = await axios({method: 'POST', url: `${api_url}`,  headers:{"Content-Type":"application/json"}, data: `${body}`})

        // console.log(rsp)
        // return(JSON.stringify(rsp))
        return(rsp)
        
    }catch(err){
        console.log(err)
        // return(JSON.stringify(err))
        return(err)
    }
    
    // axios({method: 'POST', url: `${api_url}`,  headers:{"Content-Type":"application/json"}, data: `${body}`}).then(response =>{
    //     console.log(response)
    // }).catch(error=>{
    //     console.log(error)
    //   })

  }

