<template>
  <div id="app">
    <NavBar />
    <router-view/>
    <Footer/>
  </div>

  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
  <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  
</template>
<script>
import NavBar from './components/NavBar.vue'
import store from '@/store'
import { provide } from 'vue-demi'
import Footer from './components/Footer.vue'

export default ({
  components : { NavBar, Footer },
  setup() {
    provide('store' , store)
    
  },
})
</script>


