<template>
<div class="footer">Copyright © 2021 Bill Scanners | All Rights Reserved</div> 
</template>

<script>
export default {
    name: 'Footer'
}
</script>

<style>
</style>