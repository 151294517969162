<template>
     <div class="container-fluid bsheader">
      <div class="container">
      <nav class="navbar navbar-light navbar-expand-md bg-faded justify-content-center">
          <a class="navbar-brand d-flex w-50 mr-auto" href="#" >
                <img src="assets/logo.svg" class="d-inline-block" alt="Bill Scanners" style="width:100px;height:77px;" />
              </a>
        <button class="navbar-toggler mob-menu" type="button" data-toggle="collapse" data-target="#collapsingNavbar3">
            <img src="assets/menu-mobile.svg" class="d-inline-block mob-menu-img" alt="Bill Scanners" />
        </button>
        <div class="navbar-collapse collapse w-100" id="collapsingNavbar3">
            <ul class="nav navbar-nav ml-auto w-100 justify-content-end">
                <li class="nav-item mb-2 active">
                  <router-link class="nav-link" :to="{name : 'QuickConnect'}" href="QuickConnect#" >Quick Connect</router-link>
                </li>
              </ul>
              <form class="form-inline ml-3">
              <ul class="navbar-nav mr-auto ">
                <li class="nav-item mb-2 bg-primary text-white">
                  <router-link class="bs-btn" :to="{name : 'SignIn'}" href="SignIn#" >Register</router-link>
                </li>
              </ul>
            </form>
        </div>
       </nav>
      </div> 
    <div class="container">
      <div class="container card mt-5 mb-5 bscard">
        <div class="row">
          <div class="col-md-12">
            <div class="col-md-6 offset-md-3 text-center mt-3 mb-5">
              <h1 class="text-success">Thank You !</h1>
              <h4>Registration Successful</h4>
              <div class="mt-3">Thank you for registering with Bill Scanners.Please keep your latest bills ready and our experts will connect with you shortly.<br>Stay tuned !</div>
              <img src="  assets/banner-image-005.png" class="d-inline-block w-100" alt="Bill Scanners" />
                
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
</template>

<script>
export default {
    name: 'Thanks'
}
</script>

<style>
</style>