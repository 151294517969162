<template>
   <div class="container">
      <div class="container card mt-5 mb-5 bscard">
        <div class="row">
          <div class="col align-self-center">          
                <img src="assets/banner-image-003.png" class="d-inline-block w-100" alt="Bill Scanners" />
                <h2 class="text-center pt-5">Trimming bills to its lowest</h2>
          </div>
            <div class="col">
              <div class="form-row rootbox">
                <div class="stepwizard col-md-offset-3">
                      <div class="stepwizard-row setup-panel">
                            <div class="stepwizard-step">
                              <a href="#step-1" type="button" class="btn btn-primary btn-circle">1</a>
                              <p>Step 1</p>
                            </div>
                            <div class="stepwizard-step">
                              <a href="#step-2" type="button" class="btn btn-default btn-circle" disabled="disabled">2</a>
                              <p>Step 2</p>
                            </div>
                      </div>
                </div>
              
                <form role="form" action="" method="post">
                  <div class="row setup-content" id="step-1">
                    <div class="col-xs-6 col-md-offset-3">
                      <div class="form-row">
                      <div class="form-group col-md-12">
                        <h3> Step 1</h3>
                        </div>
                        <div class="form-group col-md-6">
                              <input type="text" placeholder="Enter First Name" @change="concatFunction" v-model="state.firstname" required="required" />
                              <span style="color:Red" v-if="v$.firstname.$error" >
                                <i>{{ v$.firstname.$errors[0].$message }} </i>
                              </span>
                        </div>
                        <div class="form-group col-md-6">
                              <input type="text" placeholder="Enter Last Name" @change="concatFunction" v-model="state.lastname" required="required" />
                              <span style="color:Red" v-if="v$.lastname.$error" >
                                <i>{{ v$.lastname.$errors[0].$message }} </i>
                              </span>
                        </div>
                        <div class="form-group col-md-12">
                           <input type="text" placeholder="Your Company" v-model="state.company"/>
                          <span style="color:Red" v-if="v$.company.$error" >
                            <i>{{ v$.company.$errors[0].$message }} </i>
                          </span>
                         </div>
                        <div class="form-group col-md-12">
                          <input type="email" placeholder="Your E-mail" v-model="state.email"/>
                          <span style="color:Red" v-if="v$.email.$error" >
                            <i>{{ v$.email.$errors[0].$message }} </i>
                          </span>
                        </div>
                        <div class="form-group col-md-12">
                          <input type="text" placeholder="Your Phone" v-model="state.mobile"/>
                          <span style="color:Red" v-if="v$.mobile.$error">
                          <i>{{ v$.mobile.$errors[0].$message }}</i>
                          </span>
                         
                         </div>
                        <div class="form-group col-md-6">
                            <input type="password" placeholder="Password" v-model="state.password.password"/>
                            <span style="color:Red" v-if="v$.password.password.$error" >
                            <i>{{ v$.password.password.$errors[0].$message }} </i>
                            </span>
                        </div>
                        <div class="form-group col-md-6">
                                <input type="password" placeholder="Confirm Password" v-model="state.password.confirm"/>
                                <span style="color:Red" v-if="v$.password.confirm.$error" >
                                  <i>{{ v$.password.confirm.$errors[0].$message }} </i>
                                </span>
                          </div>
                        <div class="form-group col-md-12">
                        <button class="btn btn-primary nextBtn btn-lg bs-btn w-100" type="button">Next</button>
                        <!--  USE THE LOGIC FOR OTP Auth trigerred from this button-->
                        <label class="mt-3">By clicking next, an OTP will be sent to you via Email and Text.</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row setup-content" id="step-2">
                    <div class="col-xs-6 col-md-offset-3">
                      <div class="form-row">
                        <div class="form-group col-md-12">
                          <h3> Step 2</h3>
                          </div>
                          
                          <div class="form-group col-md-12">
                            <h6>Mobile Phone Verification </h6>
                            <label class="control-label">Please enter the 6-digit verification code</label>
                            <label class="control-label">An OTP has been sent on your mobile number </label>
                            <div class="digit-group" data-group-name="digits" data-autosubmit="false" autocomplete="off">
                              <input class="form-control" type="text" id="digit-1" name="digit-1" data-next="digit-2" />
                              <input class="form-control" type="text" id="digit-2" name="digit-2" data-next="digit-3" data-previous="digit-1" />
                              <input class="form-control" type="text" id="digit-3" name="digit-3" data-next="digit-4" data-previous="digit-2" />
                              <span class="splitter">&ndash;</span>
                              <input class="form-control" type="text" id="digit-4" name="digit-4" data-next="digit-5" data-previous="digit-3" />
                              <input class="form-control" type="text" id="digit-5" name="digit-5" data-next="digit-6" data-previous="digit-4" />
                              <input class="form-control" type="text" id="digit-6" name="digit-6" data-previous="digit-5" />
                            </div>
                            <button class="btn btn-primary prevBtn btn-lg bs-btn mt-3" type="button">Verify</button>  
                            <div class="mt-3">                            
                              <div class="control-label">Didn't receive the code?</div>
                                <a href="#">Send code again</a>
                              </div>  
                            <hr/>                     
                          </div>
                          
                          <div class="form-group col-md-12">                          
                            <input type="date" v-model="state.dob"/>
                            <span style="color:Red" v-if="v$.dob.$error" >
                            <i>{{ v$.dob.$errors[0].$message }} </i>
                            </span>
                           </div>
                          <div class="form-group col-md-12">
                            <h6>Address</h6>                            
                          </div>
                          <div class="form-group col-md-6">
                            <label class="control-label">Street Number</label>
                            <input type="text" placeholder="Street Number" v-model="state.StreetNum"/>
                            <span style="color:Red" v-if="v$.StreetNum.$error">
                            <i>{{ v$.StreetNum.$errors[0].$message }}</i>
                            </span>
                          </div>
                          <div class="form-group col-md-6">
                            <label class="control-label">Unit number</label>
                             <input type="text" placeholder="Unit number" v-model="state.Unit"/>
                            <span style="color:Red" v-if="v$.Unit.$error">
                            <i>{{ v$.Unit.$errors[0].$message }}</i>
                            </span>
                          </div>
                          <div class="form-group col-md-12">
                            <label class="control-label">Street Name</label>
                             <input type="text" placeholder="Steet Name" v-model="state.StreetName"/>
                             <span style="color:Red" v-if="v$.StreetName.$error">
                             <i>{{ v$.StreetName.$errors[0].$message }}</i>
                             </span>
                           </div>
                          <div class="form-group col-md-6">
                            <label class="control-label">City</label>
                            <input type="text" placeholder="City" v-model="state.City"/>
                            <span style="color:Red" v-if="v$.City.$error">
                            <i>{{ v$.City.$errors[0].$message }}</i>
                            </span>
                          </div>
                          <div class="form-group col-md-6">
                            <label class="control-label">Province</label>
                            <input type="text" placeholder="Province" v-model="state.Province"/>
                            <span style="color:Red" v-if="v$.Province.$error">
                            <i>{{ v$.Province.$errors[0].$message }}</i>
                            </span>
                          </div>
                          <div class="form-group col-md-12">
                            <label class="control-label">Postal Code</label>
                            <input type="text" placeholder="Postal Code" v-model="state.PostalCode"/>
                            <span style="color:Red" v-if="v$.PostalCode.$error">
                            <i>{{ v$.PostalCode.$errors[0].$message }}</i>
                            </span>
                          
                          </div>
                          <div class="form-group col-md-12">
                            <button class="btn btn-primary prevBtn btn-lg bs-btn float-left" type="button">Previous</button>
                            <button class="btn btn-success btn-lg bs-btn float-right" type="submit" onclick="handleSubmit;location.href='thanks.html'">Submit</button>
                            <!--
                              <router-link class="bs-btn" :to="{name : 'Thanks'}" href="Thanks#" ></router-link> 
                             -->
                          </div>
                        </div>                     
                    </div>
                  </div>
                </form>
              
            </div>
            </div>
        </div>
      </div>
    </div>



</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { reactive , computed, toRefs, inject } from 'vue'
import { required, email, numeric, minLength, maxLength, sameAs } from '@vuelidate/validators'
import { getlogin } from '../store/modules/auth/index.js'
import $ from 'jquery'

$(document).ready(function () {
        var navListItems = $('div.setup-panel div a'),
                allWells = $('.setup-content'),
                allNextBtn = $('.nextBtn'),
              allPrevBtn = $('.prevBtn');
      
        allWells.hide();
      
        navListItems.click(function (e) {
            e.preventDefault();
            var $target = $($(this).attr('href')),
                    $item = $(this);
      
            if (!$item.hasClass('disabled')) {
                navListItems.removeClass('btn-primary').addClass('btn-default');
                $item.addClass('btn-primary');
                allWells.hide();
                $target.show();
                $target.find('input:eq(0)').focus();
            }
        });
        
        allPrevBtn.click(function(){
            var curStep = $(this).closest(".setup-content"),
                curStepBtn = curStep.attr("id"),
                prevStepWizard = $('div.setup-panel div a[href="#' + curStepBtn + '"]').parent().prev().children("a");
                prevStepWizard.removeAttr('disabled').trigger('click');
        });
      
        allNextBtn.click(function(){
            var curStep = $(this).closest(".setup-content"),
                curStepBtn = curStep.attr("id"),
                nextStepWizard = $('div.setup-panel div a[href="#' + curStepBtn + '"]').parent().next().children("a"),
                curInputs = curStep.find("input[type='text'],input[type='url']"),
                isValid = true;
                console.log(isValid);
      
            $(".form-group").removeClass("has-error");
            for(var i=0; i<curInputs.length; i++){
                if (!curInputs[i].validity.valid){
                    isValid = false;
                    $(curInputs[i]).closest(".form-group").addClass("has-error");
                }
            }
                nextStepWizard.removeAttr('disabled').trigger('click');
        });
      
        $('div.setup-panel div a.btn-primary').trigger('click');


        $('.digit-group').find('input').each(function() {
          $(this).attr('maxlength', 1);
          $(this).on('keyup', function(e) {
            var parent = $($(this).parent());
            
            if(e.keyCode === 8 || e.keyCode === 37) {
              var prev = parent.find('input#' + $(this).data('previous'));
              
              if(prev.length) {
                $(prev).select();
              }
            } else if((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 65 && e.keyCode <= 90) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 39) {
              var next = parent.find('input#' + $(this).data('next'));
              
              if(next.length) {
                $(next).select();
              } else {
                if(parent.data('autosubmit')) {
                  parent.submit();
                }
              }
            }
          });
        });
      });

//import { axios } from 'axios'

//const displayname = firstname.concat(lastname)

export default {
  setup(){
    //ref
    
    const state = reactive({
      firstname : '',
      lastname : '',
      company : '',
      email : '' ,
      mobile : '',
      password : {
        password : '',
        confirm : '',
      },
      dob : '',
      StreetNum :'',
      Unit : '',
      StreetName : '',
      City : '',
      Province : '',
      PostalCode : ''
    })

    const err =reactive({
      isErr : false,
      errMsg: ''
    })

    const rules = computed( () => {
      return{
        firstname :{ required ,$autoDirty: true },
        lastname :{ required ,$autoDirty: true },
        company :{ required ,$autoDirty: true },
        email : { required ,  email , $autoDirty: true},
        mobile : { required, numeric, minLength: minLength(10) ,maxLength: maxLength(10) },
        password:{
          password: { required, minLength: minLength(6) , $autoDirty: true},
          confirm: { required, sameAs: sameAs(state.password.password) , $autoDirty: true},
        },
        dob : { required},
        StreetNum :{ required ,$autoDirty: true },
        Unit :{ required ,$autoDirty: true },
        StreetName :{ required ,$autoDirty: true },
        City :{ required ,$autoDirty: true },
        Province :{ required ,$autoDirty: true },
        PostalCode :{ required ,$autoDirty: true }
      }
    })

    const store = inject('store')
    //let $autoDirty = true
    const v$ = useVuelidate(rules , toRefs(state))
    return { state, store, v$ ,err}
  },


  methods:{
    async handleSubmit () {
      //console.log( this.state.displayname, this.state.email, this.state.password.password, this.state.password.confirm)
      this.v$.$validate() // checks all inputs
      // console.log(this.v$.$errors)
      if (!this.v$.$error) { // if ANY fail validation
        // alert('Form successfully submitted.')
        const resp = await getlogin(this.state.firstname, this.state.lastname, this.state.email,this.state.password.password)
        if (resp.error) {
          this.err.isErr =true
          this.err.errMsg = resp.error_msg
        } else {
          alert('we have send link to your email, please verify your e-mail to proceed' )
          this.err.isErr=false
          this.err.errMsg=''
        }
        
      } else {
        // alert('Form failed validation')
      }
    }
  },
}
</script>

<style scoped>
.root {
  width: 500px;
  margin: 0 auto;
  background-color: #fff;
  padding: 30px;
  margin-top: 20px;
  border-radius: 20px;
}

input {
  border: none;
  outline: none;
  border-bottom: 1px solid #ddd;
  font-size: 1em;
  padding: 5px 0;
  margin: 10px 0 5px 0;
  width: 100%;
}

button {
  background-color: #3498db;
  padding: 10px 20px;
  margin-top: 10px;
  border: none;
  color: white;
}

.demo {
  font-family: sans-serif;
  border: 1px solid #eee;
  border-radius: 2px;
  padding: 20px 30px;
  margin-top: 1em;
  margin-bottom: 40px;
  user-select: none;
  overflow-x: auto;
}
</style>